<template>
  <v-container fluid flex="auto">
    <SecondaryNavigation />
    <v-card class="mt-4 mb-4">
      <v-container fluid class="pa-6">
        <v-row>
          <v-card-title>Trainer Personal Detials </v-card-title>
          <v-spacer></v-spacer>
          <v-card-title>Verified Checkbox</v-card-title>
          <v-checkbox input-value="true" value></v-checkbox>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-img
              :src="`https://picsum.photos/500/300?image=${1 * 1 + 10}`"
              :lazy-src="`https://picsum.photos/10/6?image=${1 * 1 + 10}`"
              aspect-ratio="1"
              class="grey lighten-2 .rounded-circle"
              height="250px"
              width="250px"
            >
            </v-img>
            <v-file-input
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Upload Photo"
              prepend-icon="mdi-camera"
              label="Profile Picture"
            ></v-file-input>
          </v-col>
          <v-col>
            <v-text-field class=" " label="First Name" value="David" disabled></v-text-field>
            <v-text-field label="Registered Email Id" value="david@ordertraining.com" disabled></v-text-field>
            <v-text-field label="Primary Mobile Number" value="+917781904159" disabled></v-text-field>
          </v-col>
          <v-col>
            <v-text-field label="Middle Name" value="Raj" disabled></v-text-field>
            <v-text-field label="Secondary Email Id" value="davirajbit@gmail.com" disabled></v-text-field>
            <v-text-field label="Secondary Mobile Number" value="+917781904159" disabled></v-text-field>
          </v-col>
          <v-col>
            <v-text-field label="Last Name" value="---" disabled></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="mt-4 mb-4">
      <v-container fluid>
        <v-row>
          <v-card-title>Trainer Persona</v-card-title>
          <v-spacer> </v-spacer>
          <v-card-title>Admin Activity</v-card-title>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              :items="trainersPersona"
              label="Select trainer persona"
              dense
              solo
              value="Employee of a Training Firm"
              disabled
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-row>
            <!-- <v-col cols=""> -->
            <v-card-title>Verified Checkbox</v-card-title>
            <!-- </v-col> -->
            <!-- <v-col cols="4"> -->
            <v-checkbox input-value="true" value></v-checkbox>
            <!-- </v-col> -->
          </v-row>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="mt-4 mb-4">
      <v-container fluid>
        <v-row>
          <v-card-title>Headline</v-card-title>
          <v-spacer></v-spacer>
          <v-card-title>Admin Activity</v-card-title>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-textarea
            disabled
              outlined
              name="input-7-4"
              label=""
              value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
            ></v-textarea>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-row>
              <v-col>Verified Checkbox</v-col>
              <v-spacer></v-spacer>
              <v-col><v-checkbox input-value="true" value></v-checkbox></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="mt-4 mb-4">
      <v-container fluid>
        <v-row>
          <v-card-title>Professional Overview</v-card-title>
          <v-spacer></v-spacer>
          <v-card-title>Admin Activity</v-card-title>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-textarea
            disabled
              outlined
              name="input-7-4"
              label=""
              value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
            ></v-textarea>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-row>
              <v-col>Verified Checkbox</v-col>
              <v-spacer></v-spacer>
              <v-col><v-checkbox input-value="true" value></v-checkbox></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-row>
      <v-col cols="6">
        <v-card class="mt-4 mb-4">
          <v-container>
            <v-row>
              <v-card-title>Link to personal website</v-card-title>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-text-field placeholder="type here" value="www.davidarj.com" disabled></v-text-field>
                <v-text-field placeholder="type here" value="www.davidarj.com" disabled></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card class="mt-4 mb-4">
          <v-container fluid>
            <v-row>
              <v-card-title>Link to social media</v-card-title>
            </v-row>
            <v-row>
              <v-col cols="8">
                <label>LinkedIn</label>
                <v-text-field placeholder="type here" value="www.davidarj.com" disabled></v-text-field>
                <label>Twitter</label>
                <v-text-field placeholder="type here" value="www.davidarj.com" disabled></v-text-field>
                <label>Instagram</label>
                <v-text-field placeholder="type here" value="www.davidarj.com" disabled></v-text-field>
                <label>Facebook</label>
                <v-text-field placeholder="type here" value="www.davidarj.com" disabled></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card class="mt-4 mb-4">
          <v-container fluid>
            <v-row>
              <v-card-title>Link to professional network</v-card-title>
            </v-row>
            <v-row>
              <v-col cols="8">
                <label>Udemy</label>
                <v-text-field placeholder="type here" value="www.davidarj.com" disabled></v-text-field>
                <label>Pluralsight</label>
                <v-text-field placeholder="type here" value="www.davidarj.com" disabled></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-4 mb-4">
      <v-container fluid>
        <v-row>
          <v-col cols="8">
            <v-card-title>Introduction to vedio pitch</v-card-title>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-card-title>Admin Activity</v-card-title>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="mt-4 mb-4">
      <v-container fluid>
        <v-row>
          <v-col cols="8">
            <v-card-title>Training Vedio</v-card-title>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-card-title>Admin Activity</v-card-title>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-row>
      <v-col cols="6">
        <v-card class="mt-4 mb-4">
          <v-container>
            <v-row>
              <v-card-title>Languages</v-card-title>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-autocomplete
                  auto-select-first
                  chips
                  clearable
                  deletable-chips
                  solo
                  :items="languages"
                  value="English"
                  disabled
                ></v-autocomplete>
                <v-select
                  :items="languageProficiency"
                  label="Select proficiency"
                   value="Elementary proficiency"
                   disabled
                  dense
                  solo
                ></v-select>
                <v-autocomplete
                  class=".rounded-xl"
                  auto-select-first
                  chips
                  clearable
                  deletable-chips
                  solo
                  :items="languages"
                  value="Hindi"
                  disabled
                ></v-autocomplete>
                <v-select
                  :items="languageProficiency"
                  label="Select proficiency"
                   value="Elementary proficiency"
                  auto-select-first
                  disabled
                  dense
                  solo
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card class="mt-4 mb-4">
          <v-container>
            <v-row>
              <v-card-title>
                Address
              </v-card-title>
            </v-row>
            <v-row>
              <v-col cols="8">
                <label>House Number </label>
                <v-text-field placeholder="Ennter House Number" value="Fig 105" disabled></v-text-field>
                <label>Street Name</label>
                <v-text-field placeholder="Enter Street Number" value="Srcm road" disabled></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <label>Pincode</label>
                <v-autocomplete
                  auto-select-first
                  chips
                  clearable
                  deletable-chips
                  solo
                  :items="pincodes"
                  value="7001560"
                  disabled
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <AdminComments/>
  </v-container>
</template>

<script>
import SecondaryNavigation from "../../../components/SecondaryNavigation/SecondaryNavigation.vue";
import AdminComments from '../../../components/AdminComments/AdminComments.vue'
export default {
name:"TrainerDetials",
components: {
    //BasicForm,
    SecondaryNavigation,
    AdminComments
  },
  data() {
    return {
        pincodes:["7001560" ,"77851818"],
        languages:["English" ,"Hindi" , "German"],
      trainersPersona: [
        "Full-time Freelancer",
        "Part-time Freelancer",
        "Founder of a training/ company",
        "Founder with non training company",
        "Consultant",
        "Employee of a Training Firm",
        "Faculty in an Academic Institution"
      ],
      languageProficiency: [
        "Elementary proficiency",
        "Limited working proficiency",
        "Full professional proficiency",
        "Professional working proficiency",
        "Native or bilingual proficiency"
      ]
    };
  }
}
</script>

<style>

</style>